<div>
  <form [formGroup]="form">
    <div class="container-data">
      <card>
        <div class="card-header">
          <h4 class="card-title">Dados da locação</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-4">
              <label class="form-label">Cliente</label>
              <dg-autocomplete
                formControlName="cliente"
                typeFilter="outside"
                key="id"
                label="nome"
                classes="form-control"
                (searchFilterValue)="this.findCliente($event)"
                [data]="listClientes"
              ></dg-autocomplete>
            </div>
            <div class="col-4">
              <label class="form-label">Contato</label>
              <select class="form-select" formControlName="contato">
                <option disabled selected value="">Selecione um cliente</option>
                <option *ngFor="let item of listContato" [value]="item.id">
                  Id: {{item?.id}} | Desc.:{{item?.descricao}} | Tel.:{{item?.telefone}} | Email:{{item?.email}}
                </option>
              </select>
            </div>
            <div class="col-4">
              <label class="form-label">Endereço</label>
              <select class="form-select" formControlName="endereco">
                <option disabled selected value="">Selecione um cliente</option>
                <option *ngFor="let item of listEndereco" [value]="item.id">
                  Id: {{item?.id}} | Desc.: {{item?.descricao}} | Rua: {{item?.rua}} | N: {{item?.numero}}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <label class="form-label">Data Inicial</label>
              <input class="form-control" type="date" formControlName="dataInicial">
            </div>
            <div class="col-4">
              <label class="form-label">Data Final</label>
              <input class="form-control" type="date" formControlName="dataFinal">
            </div>
          </div>
        </div>
      </card>
    </div>

    <div class="container-data">
      <card>
        <div class="card-header">
          <h4 class="card-title">Informações</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <label class="form-label">Descrição</label>
              <textarea rows="8" class="form-control" formControlName="descricao"></textarea>
            </div>
            <div class="col-6">
              <label class="form-label">Observações <span class="fs-8 fc-grey">Não serão vistas pelo cliente</span> </label>
              <textarea rows="8" class="form-control" formControlName="observacao"></textarea>
            </div>
          </div>
        </div>
      </card>
    </div>
  </form>


  <div *ngIf="!!entity?.id" class="container-data">
    <card>
      <div class="card-header">
        <div class="row">
          <div class="col-4">
            <h4 class="card-title">Produtos</h4>
          </div>
          <div class="col-6">
            <h5 class="card-title">Valor total de produtos: R${{entity.totalProdutos || '0,00'}}</h5>
          </div>
          <div class="col-2">
            <button *ngIf="verifyEdit()" (click)="modalProduto.open()" class="btn btn-success">Adicionar</button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <dg-table dg-dynamic-table [dynamicTable]="tableProtudos" [showActions]="verifyEdit()">
          </dg-table>
        </div>
      </div>
      <div class="card-footer">
        <dg-paginator dg-dynamic-paginator [dynamicTable]="tableProtudos"></dg-paginator>
      </div>
    </card>
  </div>

  <div *ngIf="!!entity?.id" class="container-data">
    <card>
      <div class="card-header">
        <div class="row">
          <div class="col-4">
            <h4 class="card-title">Serviços</h4>
          </div>
          <div class="col-6">
            <h5 class="card-title">Valor total de serviços: R${{entity.totalServicos || '0,00'}}</h5>
          </div>
          <div class="col-2">
            <button *ngIf="verifyEdit()" (click)="modalServico.open()" class="btn btn-success">Adicionar</button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <dg-table dg-dynamic-table [dynamicTable]="tableServicos" [showActions]="verifyEdit()">
          </dg-table>
        </div>
      </div>
      <div class="card-footer">
        <dg-paginator dg-dynamic-paginator [dynamicTable]="tableServicos"></dg-paginator>
      </div>
    </card>
  </div>
</div>

<page-footer>
  <button class="btn btn-success" crud-salvar [backAfterSave]="false">Salvar</button>
  <button class="btn btn-secondary" crud-voltar>Voltar</button>
  <div class="ms-auto">
    <h4>Total R$:{{ entity?.total || '0,00' }}</h4>
    <div class="w-100 d-flex justify-content-end">
      <span
        class="badge status ms-auto"
        [class.text-bg-secondary]="entity?.status == 'ABERTA'"
        [class.text-bg-success]="entity?.status == 'FINALIZADO'"
        [class.text-bg-warning]="entity?.status == 'EM_LOCACAO'"
      >{{entity?.status}}</span>
    </div>
  </div>
</page-footer>


<modal-produto-locacao #modalProduto (afterSave)="tableProtudos.find(); atualizaTotais()" [licacaoId]="entity?.id"></modal-produto-locacao>
<modal-servico-locacao #modalServico (afterSave)="tableServicos.find(); atualizaTotais()" [licacaoId]="entity?.id"></modal-servico-locacao>
