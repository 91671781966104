<dg-modal
  #enderecosModal
  title="Endereços"
  (afterClose)="close()"
>
  <div modal-body>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-xl-11 col-lg-10 col-md-10 col-sm-12">
          <label class="form-label">Nome</label>
          <input formControlName="descricao" class="form-control">
        </div>
        <div class="col-xl-1 col-lg-2 col-md-2 col-sm-12">
          <label class="form-label">Principal</label>
          <div class="d-flex justify-content-lg-center align-items-start">
            <input #inputPrincipal type="checkbox" formControlName="principal" hidden>
            <i
              style="font-size: 20pt"
              class="bi"
              [class.bi-star-fill]="!!form.value.principal"
              [class.fc-yellow]="!!form.value.principal"
              [class.bi-star]="!form.value.principal"
              (click)="inputPrincipal.click()"
            ></i>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <label class="form-label">CEP</label>
          <input type="text" id="cep" formControlName="cep" class="form-control" mask="00.000.000">
        </div>
        <div class="col-4">
          <label class="form-label">Cidade</label>
          <input type="text" id="cidade" formControlName="cidade" class="form-control">
        </div>
        <div class="col-4">
          <label class="form-label">Bairro</label>
          <input type="text" id="bairro" formControlName="bairro" class="form-control">
        </div>
        <div class="col-1">
          <label class="form-label">UF</label>
          <input type="text" id="uf" formControlName="uf" class="form-control">
        </div>
      </div>

      <div class="row">
        <div class="col-5">
          <label class="form-label">Rua</label>
          <input type="text" id="rua" formControlName="rua" class="form-control">
        </div>
        <div class="col-2">
          <label class="form-label">Numero</label>
          <input type="text" id="numero" formControlName="numero" class="form-control">
        </div>
        <div class="col-5">
          <label class="form-label">Complemento</label>
          <input type="text" id="complemento" formControlName="complemento" class="form-control">
        </div>
      </div>
    </form>
  </div>

  <div modal-footer>
    <button class="btn btn-success" (click)="save()">Salvar</button>
    <button class="btn btn-secondary" (click)="enderecosModal.close()">Sair</button>
  </div>
</dg-modal>
