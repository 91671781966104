<div id="page-clientes" class="container">

  <card>
    <div class="card-header">
      <h4 class="card-title">Lançamentos</h4>
      <custom-filters group="lancamentos">
        <div body-filters>
          <form [formGroup]="form">
            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <label class="form-label">Nome</label>
                <input
                  class="form-control"
                  formControlName="nome"
                  dg-dynamic-group="lancamentos"
                  paramName="nome"
                  (clearParams)="form.patchValue({ nome: '' })"
                >
              </div>
              <div class="col-xl-1 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">ID</label>
                <input class="form-control" formControlName="id" dg-dynamic-group="lancamentos"
                       paramName="id">
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <label class="form-label">Tipo Lançamento</label>
                <select
                  class="form-control"
                  formControlName="tipo"
                  dg-dynamic-group="lancamentos"
                  paramName="tipo"
                  (clearParams)="form.patchValue({ tipo: '' })"
                >
                  <option value="">Ambos</option>
                  <option value="RECEITA">Receita</option>
                  <option value="DESPESA">Despesa</option>
                </select>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <label class="form-label">Pago</label>
                <select
                  class="form-control"
                  formControlName="baixado"
                  dg-dynamic-group="lancamentos"
                  paramName="baixado"
                  (clearParams)="form.patchValue({ baixado: '' })"
                >
                  <option value="">Ambos</option>
                  <option [value]="true">Sim</option>
                  <option [value]="false">Não</option>
                </select>
              </div>
            </div>
          </form>
        </div>
      </custom-filters>
    </div>
    <div class="card-body">
      <dg-table dg-dynamic-table [dynamicTable]="table"></dg-table>
    </div>
    <div class="card-footer">
      <dg-paginator dg-dynamic-paginator [dynamicTable]="table"></dg-paginator>
    </div>
  </card>
</div>

<page-footer>
  <button class="btn btn-success" (click)="modalLancamentos.addReceita()">Adicionar Receita</button>
  <button class="btn btn-danger" (click)="modalLancamentos.addDespesa()">Adicionar Despesa</button>
</page-footer>


<modal-lancamento (afterSave)="table.find()" #modalLancamentos></modal-lancamento>

<modal-baixar #modalBaixar (afterSave)="table.find()"></modal-baixar>
