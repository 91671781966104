<div class="container">
  <card>
    <div class="card-header">
      <h4 class="card-title">Produto</h4>
    </div>
    <div class="card-body">
      <form [formGroup]="form">
        <div class="row">
          <div class="col-7">
            <label id="nome" class="form-label">Nome do Produto</label>
            <input class="form-control" type="text" formControlName="nome">
          </div>
          <div class="col-5">
            <label id="categoria" class="form-label">Categoria</label>
            <select class="form-select" formControlName="categoria">
              <option>Selecione uma categoria</option>
              <option *ngFor="let categ of listCategorias" [value]="categ.id">{{categ.nome}}</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <label class="form-label">Preço de Locacao</label>
            <input-number formControlName="preco" order="reverse"></input-number>
          </div>
          <div class="col-4">
            <label class="form-label">Estoque Atual</label>
            <input id="estoqu" class="form-control" type="number" formControlName="estoqueAtual">
          </div>
          <div class="col-4">
            <label class="form-label">Alerta de Estoque mínimo</label>
            <input id="alerta" class="form-control" type="number" formControlName="alertaEstoqueMinimo">
          </div>
        </div>

        <div class="row">
          <h5>Deseja que este produto seja anunciado no site?</h5>
          <div class="col-6">
            <input class="form-check-input" type="radio" id="visivel" formControlName="disponivelSite" [value]="true">
            <label class="form-check-label" for="visivel">
              Sim
            </label>
          </div>
          <div class="col-6">
            <input class="form-check-input" type="radio" id="invisivel" formControlName="disponivelSite" [value]="false">
            <label class="form-check-label" for="invisivel">
              Não
            </label>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <textarea id="descricao" formControlName="descricao" style="width: 100%">
            </textarea>
          </div>
        </div>
      </form>
    </div>
    <div class="card-footer"></div>
  </card>
</div>

<page-footer>
  <button class="btn btn-success" crud-salvar>Salvar</button>
  <button class="btn btn-secondary" crud-voltar>voltar</button>
</page-footer>
