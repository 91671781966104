<div>
  <div class="row">
    <ng-content select="[body-filters]"></ng-content>
  </div>
  <div class="row mt-2">
    <div class="col-12 d-inline-flex justify-content-center">
      <button [dynamic-group-control]="group" filter class="btn btn-success m-1 btn-sm">Pesquisar</button>
      <button [dynamic-group-control]="group" clear class="btn btn-secondary m-1 btn-sm">Limpar</button>
    </div>
  </div>
</div>
