<div id="page-home">
  <div id="home-banner">
    <div class="home-banner-pelicula">
    </div>
  </div>

  <section id="section-2" class="container-fluid container-root">
    <div class="row">
      <div class="col-6">
        <img src="/assets/img/home-section-2.jpeg" style="max-width: 100%">
      </div>
      <div class="col-6">
        <h4>What is Lorem Ipsum?</h4>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
          standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
          make a type specimen book. It has survived not only five centuries, but also the leap into electronic
        </p>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
          standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
          make a type specimen book. It has survived not only five centuries, but also the leap into electronic
        </p>
      </div>
    </div>
  </section>
  <section id="section-3" class="container-fluid container-root">
    <div class="row">
      <h4>Confira alguns produtos</h4>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="card" style="width: 18rem;">
          <img src="/assets/img/mock-img-andaime.webp" class="card-img-top" alt="...">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            <a href="#" class="btn btn-primary">Go somewhere</a>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card" style="width: 18rem;">
          <img src="/assets/img/mock-img-diagona.webp" class="card-img-top" alt="...">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            <a href="#" class="btn btn-primary">Go somewhere</a>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card" style="width: 18rem;">
          <img src="/assets/img/mock-img-andaime.webp" class="card-img-top" alt="...">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            <a href="#" class="btn btn-primary">Go somewhere</a>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card" style="width: 18rem;">
          <img src="/assets/img/mock-img-diagona.webp" class="card-img-top" alt="...">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            <a href="#" class="btn btn-primary">Go somewhere</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="card" style="width: 18rem;">
          <img src="/assets/img/mock-img-andaime.webp" class="card-img-top" alt="...">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            <a href="#" class="btn btn-primary">Go somewhere</a>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card" style="width: 18rem;">
          <img src="/assets/img/mock-img-diagona.webp" class="card-img-top" alt="...">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            <a href="#" class="btn btn-primary">Go somewhere</a>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card" style="width: 18rem;">
          <img src="/assets/img/mock-img-andaime.webp" class="card-img-top" alt="...">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            <a href="#" class="btn btn-primary">Go somewhere</a>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card" style="width: 18rem;">
          <img src="/assets/img/mock-img-diagona.webp" class="card-img-top" alt="...">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            <a href="#" class="btn btn-primary">Go somewhere</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="section-4" class="container-fluid container-root">
    <div class="row">
      <div class="col-6">
        <p>
          Estamos licalizados na rua XX, 222
        </p>
        <p>
          Cordovil, RJ
        </p>
      </div>
      <div class="col-6">
        <img src="/assets/img/mock-google-maps.webp" style="max-width: 100%">
      </div>
    </div>
  </section>
  <section id="section-5" class="container-fluid container-root">
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col-6">
            <label>Nome</label>
            <input class="form-control">
          </div>
          <div class="col-6">
            <label>Telefone para contato</label>
            <input class="form-control">
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label>E-mail</label>
            <input class="form-control">
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label></label>
            <textarea class="form-control"></textarea>
          </div>
        </div>
      </div>
      <div class="col-6">
        <h4>What is Lorem Ipsum?</h4>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
          standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
          make a type specimen book. It has survived not only five centuries, but also the leap into electronic
        </p>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
          standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
          make a type specimen book. It has survived not only five centuries, but also the leap into electronic
        </p>
      </div>
    </div>
  </section>
</div>
