<div id="page-clientes" class="container">

  <card>
    <div class="card-header">
      <h4 class="card-title">Usuários</h4>
    </div>
    <div class="card-body">
      <dg-table dg-dynamic-table [dynamicTable]="table"></dg-table>
    </div>
    <div class="card-footer">
      <dg-paginator dg-dynamic-paginator [dynamicTable]="table"></dg-paginator>
    </div>
    <div class="card-footer">
      <button class="btn btn-success" [routerLink]="'novo'">Adicionar</button>
    </div>
  </card>
</div>
