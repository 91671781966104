<nav id="public-id" class="navbar">
  <div class="container-fluid container-root navbar-expand-md">
    <a class="navbar-brand" href="#">Socel andaimes</a>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item">
          <a class="nav-link active" routerLink="/">Home</a>
        </li>
        <!--          <li class="nav-item">-->
        <!--            <a class="nav-link" href="#">Features</a>-->
        <!--          </li>-->
        <!--          <li class="nav-item">-->
        <!--            <a class="nav-link" href="#">Pricing</a>-->
        <!--          </li>-->
        <li class="nav-item">
          <a class="nav-link" routerLink="/login">Login</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<router-outlet></router-outlet>
