<div id="page-clientes" class="container-fluid">

  <card>
    <div class="card-header">
      <h4 class="card-title">Locações</h4>
    </div>
    <div class="card-header container-fluid">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <div class="row">
            <span class="fs-9">Iniciando entre</span>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <label class="form-label">Data Inicial</label>
              <input
                type="date"
                class="form-control"
                dg-dynamic-group="locacoes"
                paramName="dataInicial_inicio"
                [(ngModel)]="filters.dataInicial_inicio"
              >
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <label class="form-label">Data Final</label>
              <input
                type="date"
                class="form-control"
                dg-dynamic-group="locacoes"
                paramName="dataInicial_fim"
                [(ngModel)]="filters.dataInicial_fim"
                (clearParams)="filters.dataInicial_fim = ''"
              >
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <div class="row">
            <span class="fs-9">Finalizando entre</span>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <label class="form-label">Data Inicial</label>
              <input
                type="date"
                class="form-control"
                dg-dynamic-group="locacoes"
                paramName="dataFinal_inicio"
                [(ngModel)]="filters.dataFinal_inicio"
                (clearParams)="filters.dataFinal_inicio = ''"
              >
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <label class="form-label">Data Final</label>
              <input
                type="date"
                class="form-control"
                dg-dynamic-group="locacoes"
                paramName="dataFinal_fim"
                [(ngModel)]="filters.dataFinal_fim"
                (clearParams)="filters.dataFinal_fim = ''"
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-5 col-lg-6 col-md-12 col-sm-12">
          <label class="form-label">Nome do cliente</label>
          <input
            class="form-control"
            dg-dynamic-group="locacoes"
            paramName="nomeCliente"
            [(ngModel)]="filters.nomeCliente"
            (clearParams)="filters.nomeCliente"
          >
        </div>
        <div class="col-xl-1 col-lg-6 col-md-12 col-sm-12">
          <label class="form-label">ID</label>
          <input class="form-control" [(ngModel)]="filters.id" dg-dynamic-group="locacoes"
                 paramName="id">
        </div>
        <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12">
          <label class="form-label">Renovada</label>
          <input
            class="form-control"
            dg-dynamic-group="locacoes"
            paramName="renovada"
            [(ngModel)]="filters.renovada"
            (clearParams)="filters.renovada"
          >
        </div>
        <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12">
          <label class="form-label">Faturada</label>
          <select
            class="form-select"
            dg-dynamic-group="locacoes"
            paramName="faturada"
            [(ngModel)]="filters.faturada"
            (clearParams)="filters.faturada"
          >
            <option value=""></option>
            <option value="ABERTO">Aberto</option>
            <option value="EM_LOCACAO">Em locação</option>
            <option value="FINALIZADO">Finalizado</option>
          </select>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12">
          <label class="form-label">Status</label>
          <select
            class="form-select"
            dg-dynamic-group="locacoes"
            paramName="status"
            [(ngModel)]="filters.status"
            (clearParams)="filters.status"
          >
            <option value=""></option>
            <option value="ABERTO">Aberto</option>
            <option value="EM_LOCACAO">Em locação</option>
            <option value="FINALIZADO">Finalizado</option>
          </select>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 d-inline-flex justify-content-center">
          <button dynamic-group-control="locacoes" filter class="btn btn-success btn-sm m-1">Filtrar</button>
          <button dynamic-group-control="locacoes" clear class="btn btn-secondary btn-sm m-1">Limpar</button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <dg-table dg-dynamic-table [dynamicTable]="table" classes="table-locacao"></dg-table>
    </div>
    <div class="card-footer">
      <dg-paginator dg-dynamic-paginator [dynamicTable]="table"></dg-paginator>
    </div>
  </card>
</div>


<page-footer>
  <button class="btn btn-success" [routerLink]="'novo'">Adicionar</button>
</page-footer>

<modal-lancamento #modalLancamento></modal-lancamento>
