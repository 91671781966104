<nav id="user-header-principal" class="navbar navbar-expand-md">
  <div class="container-fluid">

    <button style="border: none; background: none" class="mr-auto" (click)="sidebarChange()">
      <mat-icon [hidden]="sidebaOpened()">menu</mat-icon>
      <mat-icon [hidden]="!sidebaOpened()">close</mat-icon>
<!--      <mat-icon>close</mat-icon>-->
    </button>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#header-user-minha-coleta" aria-controls="header-user-minha-coleta" aria-expanded="false" aria-label="Toggle navigation">
      <mat-icon>menu</mat-icon>
    </button>

    <div class="collapse navbar-collapse h-100" id="header-user-minha-coleta">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0 h-100">

        <li class="nav-item">
          <span style="cursor:pointer" (click)="sair()" class="nav-link" aria-current="page">Sair</span>
        </li>
      </ul>
    </div>

  </div>
</nav>
