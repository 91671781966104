<dg-modal
  #modal
  (afterClose)="clear()"
  [class.modal-fatura-receita]="entity.tipo == 'RECEITA'"
  [class.modal-fatura-despesa]="entity.tipo == 'DESPESA'"
>
  <div modal-body>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-12">
          <label>Cliente</label>
          <dg-autocomplete
            classes="form-control"
            formControlName="cliente"
            key="id"
            label="nome"
            [data]="listClientes"
          ></dg-autocomplete>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <label>Valor</label>
          <input-number formControlName="total" order="reverse"></input-number>
        </div>
        <div class="col-6">
          <label>Vencimento</label>
          <input type="date" class="form-control" formControlName="data_vencimento">
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <div class="alert alert-secondary" role="alert">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" formControlName="baixado" id="fatura_paga">
              <label class="form-check-label fs-12" for="fatura_paga">
                Essa fatura já está paga
              </label>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="form.value.baixado" class="row mb-3">
        <div class="col-4">
          <label class="form-label">Data de pagamento</label>
          <input type="date" class="form-control" formControlName="data_pagamento">
        </div>
        <div class="col-4">
          <label class="form-label">Forma de pagamento</label>
          <select class="form-select" formControlName="forma_pgto">
            <option value="DINHEIRO">Dinheiro</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <label>Descrição</label>
          <textarea formControlName="descricao" class="form-control"></textarea>
        </div>
      </div>
    </form>
  </div>
  <div modal-footer>
    <button class="btn btn-success" (click)="save()">Salvar</button>
    <button class="btn btn-secondary" (click)="modal.close()">Sair</button>
  </div>
</dg-modal>
