<div id="page-clientes" class="container">

  <card>
    <div class="card-header">
      <h4 class="card-title">Categoria</h4>
    </div>
    <div class="card-header">
      <custom-filters group="categorias">
        <div body-filters>
          <form [formGroup]="form">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <label class="form-label">Nome</label>
              <input
                class="form-control"
                formControlName="nome"
                dg-dynamic-group="categorias"
                paramName="nome"
                (clearParams)="form.patchValue({ nome: '' })"
              >
            </div>
          </form>
        </div>
      </custom-filters>
    </div>
    <div class="card-body">
      <dg-table dg-dynamic-table [dynamicTable]="table"></dg-table>
    </div>
    <div class="card-footer">
      <dg-paginator dg-dynamic-paginator [dynamicTable]="table"></dg-paginator>
    </div>
  </card>
</div>

<page-footer>
  <button class="btn btn-success" (click)="modal.open()">Novo</button>
</page-footer>

<modal-categoria-insert-edit #modal (afterSave)="table.find()"></modal-categoria-insert-edit>
