<div id="page-clientes">
  <form [formGroup]="form">

    <div class="container-data">
      <div class="row">

        <div class="col-12">
          <card>
            <div class="card-header">
              <h4 class="card-title">Cliente</h4>
            </div>
            <div class="card-body">

              <div class="row">
                <div class="col-12">
                  <div class="mb-3">
                    <label class="form-label">Nome</label>
                    <input formControlName="nome" class="form-control">
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-4">
                  <div class="mb-3">
                    <label class="form-label">Documento</label>
                    <input type="text" mask="000.000.000-00||00.000.000/0000-00" class="form-control" formControlName="documento">
                  </div>
                </div>

                <div class="col-4">
                  <label class="form-label w-100">Tipo de pessoa</label>
                  <div class="btn-group" role="group">
                    <input
                      value="FISICA"
                      formControlName="tipoPessoa"
                      class="btn-check"
                      type="radio"
                      name="tipoPessoa"
                      id="Pessoa_fisica"
                      autocomplete="off"
                    >
                    <label class="btn btn-outline-primary" for="Pessoa_fisica">Pessoa Física</label>

                    <input
                      value="JURIDICA"
                      formControlName="tipoPessoa"
                      class="btn-check"
                      type="radio"
                      name="tipoPessoa"
                      id="Pessoa_juridica"
                      autocomplete="off"
                    >
                    <label class="btn btn-outline-primary" for="Pessoa_juridica">Pessoa Jurídica</label>
                  </div>
                </div>

                <div class="col-4">
                  <label class="form-label" style="width: 100%;">Sexo</label>
                  <div class="btn-group" role="group">
                    <input
                      value="M"
                      formControlName="sexo"
                      class="btn-check"
                      type="radio"
                      name="sexo"
                      id="masculino"
                      autocomplete="off"
                    >
                    <label class="btn btn-outline-primary" for="masculino">Masculino</label>

                    <input
                      value="F"
                      formControlName="sexo"
                      class="btn-check"
                      type="radio"
                      name="sexo"
                      id="feminino"
                      autocomplete="off"
                    >
                    <label class="btn btn-outline-primary" for="feminino">Feminino</label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <h5 class="card-subtitle">Informações principais</h5>
                </div>
                <div class="col-12">
                  <p class="card-subtitle">Contato</p>
                </div>
              </div>

            </div>

          </card>
        </div>

      </div>
    </div>

    <div class="container-data">
      <div class="row">
        <div class="col-12">
          <card>
            <div class="card-header">
              <h4 class="card-title">Informações principais</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <h5 class="card-subtitle">Contato</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <label class="form-label f-bold">Descição</label>
                  <p>{{ entity?.contato?.descricao || '--' }}</p>
                </div>
                <div class="col-4">
                  <label class="form-label f-bold">Telefone</label>
                  <p>{{ entity?.contato?.telefone || '--' }}</p>
                </div>
                <div class="col-4">
                  <label class="form-label f-bold">E-mail</label>
                  <p>{{ entity?.contato?.email || '--' }}</p>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <h5 class="card-subtitle">Endereço</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <label class="form-label f-bold">Logradouro</label>
                  <p>{{ entity?.endereco?.rua || '--' }}</p>
                </div>
                <div class="col-4">
                  <label class="form-label f-bold">Numero</label>
                  <p>{{ entity?.endereco?.numero || '--' }}</p>
                </div>
                <div class="col-4">
                  <label class="form-label f-bold">Complemento</label>
                  <p>{{ entity?.endereco?.complemento || '--' }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <label class="form-label f-bold">Bairro</label>
                  <p>{{ entity?.endereco?.bairro || '--' }}</p>
                </div>
                <div class="col-4">
                  <label class="form-label f-bold">Cidade</label>
                  <p>{{ entity?.endereco?.cidade || '--' }}</p>
                </div>
                <div class="col-4">
                  <label class="form-label f-bold">Estado</label>
                  <p>{{ entity?.endereco?.uf || '--' }}</p>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>

    <div *ngIf="!!entity?.id" class="container-data">
      <div class="row">
        <card>
          <div class="card-header">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h4 class="card-title">Endereços</h4>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <button class="btn btn-success" (click)="enderecosModal.open()">Adicionar</button>
              </div>
            </div>
          </div>

          <div class="card-body">
            <dg-table dg-dynamic-table [dynamicTable]="tableEnderecos">
              <ng-template dgTableColumn columnName="principal" let-principal>
                <i *ngIf="!!principal.row?.principal" style="font-size: 14pt" class="bi bi-star-fill fc-yellow"></i>
                <i *ngIf="!principal.row?.principal" style="font-size: 10pt" class="bi bi-star"></i>
              </ng-template>
            </dg-table>
          </div>

          <div class="card-footer">
            <dg-paginator dg-dynamic-paginator [dynamicTable]="tableEnderecos"></dg-paginator>
          </div>
        </card>
      </div>
    </div>

    <div *ngIf="!!entity?.id" class="container-data">
      <div class="row">
        <card>
          <div class="card-header">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h4 class="card-title">Contatos</h4>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <button class="btn btn-success" (click)="contatoModal.open()">Adicionar</button>
              </div>
            </div>
          </div>

          <div class="card-body">
            <dg-table dg-dynamic-table [dynamicTable]="tableContatos">
              <ng-template dgTableColumn columnName="principal" let-principal>
                <i *ngIf="!!principal.row?.principal" style="font-size: 14pt" class="bi bi-star-fill fc-yellow"></i>
                <i *ngIf="!principal.row?.principal" style="font-size: 10pt" class="bi bi-star"></i>
              </ng-template>
            </dg-table>
          </div>
          <div class="card-footer">
            <dg-paginator dg-dynamic-paginator [dynamicTable]="tableContatos"></dg-paginator>
          </div>
        </card>
      </div>
    </div>
  </form>

</div>

<page-footer>
  <button class="btn btn-success" crud-salvar [backAfterSave]="false">Salvar</button>
  <button class="btn btn-success" crud-salvar [backAfterSave]="true">Salvar e Voltar</button>
  <button class="btn btn-secondary" crud-voltar>Voltar</button>
</page-footer>

<subComponent-enderecos
  #enderecosModal
  [idCliente]="entity?.id"
  (afterSave)="tableEnderecos.find()"
></subComponent-enderecos>

<subComponent-contatos
  #contatoModal
  [idCliente]="entity?.id"
  (afterSave)="tableContatos.find()"
></subComponent-contatos>
